import React from 'react';
import s from './Modal.module.css'

const Modal = () => {
    return (
        <div className={s.modal}>
            <div className={s.container}>
                <p>Ваша заявка успешно отправлена!</p>
                <p>С вами скоро свяжутся.</p>
            </div>
        </div>
    );
};

export default Modal;